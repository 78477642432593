<template>
    <EKTable
        :items="customers"
        :columns="columns"
        selectedLabel="id"
        @details="openCustomerDetails"
        @delete-selected="fireDeleteEvent"
        no_select
    >
        <template slot="items.dateCreated" slot-scope="{ value }">
            {{ new Date(value).toLocaleDateString("en-UK") }}
        </template>
    </EKTable>
</template>
<script>
import EKTable from "@Ekcore/components/EK-table";
import { mapActions, mapState } from "vuex";
export default {
    components: {
        EKTable
    },
    data: () => ({
        columns: [
            {
                label: "الاسم الكامل",
                field: "fullName"
            },
            {
                label: "الإيميل",
                field: "email"
            },
            {
                label: "العنوان",
                field: "address"
            },
            {
                label: "رقم الجوال",
                field: "mobileNumber"
            },
            {
                label: "تاريخ الإشتراك",
                field: "dateCreated",
                sortable: false
            },
            {
                label: "تفاصيل",
                field: "details",
                sortable: false
            }
        ]
    }),
    computed: {
        ...mapState({
            customers: state => state.customers.customers
        })
    },
    created() {
        this.fetchCustomers();
    },
    methods: {
        openCustomerDetails(props) {
            this.$router.push("/admin/customers/" + props.row.id);
        },
        ...mapActions(["fetchCustomers"])
    },
    beforeDestroy() {
        this.$store.commit("Reset_Search_Dto");
    }
};
</script>
